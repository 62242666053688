import { BaseDto } from '@/shared/dtos/base-dto';
import { DashTipoCitasPacientesRecuentoDto } from './DashTipoCitasPacientesRecuentoDto';
import { pacientes_y_tipos_cita } from './pacientes_y_tipos_cita';

export class EstadisticasCita extends BaseDto {
    public pacientes_y_tipos_cita: DashTipoCitasPacientesRecuentoDto[] = [];
    public paciente_mas_citas_confirmadas !: pacientes_y_tipos_cita;
    public paciente_menos_citas_confirmadas !: pacientes_y_tipos_cita;
    public paciente_mas_citas_canceladas !: pacientes_y_tipos_cita;
    public paciente_menos_citas_canceladas !: pacientes_y_tipos_cita;
} 
