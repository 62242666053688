import { API } from '@/shared/api';
import { EstadisticasCita } from '@/shared/dtos/DashboardsDtos/EstadisticasCita';
import { ssmHttpService } from '@/shared/services/http-service';
import { store } from '@/store/store';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
    namespaced: true,
    name: 'EstadisticasCitaModule',
    store,
    dynamic: true
})
class EstadisticasCitaModule extends VuexModule {
    public EstadisticasCita: EstadisticasCita = new EstadisticasCita();

    @Action({ commit: 'onGetEstadisticasCita' })
    public async getEstadisticasCitaas() {
        return await ssmHttpService.get(API.EstadisticasCita);
    }

    @Mutation
    public onGetEstadisticasCita(res: EstadisticasCita) {
        this.EstadisticasCita = res;
    }

}
export default getModule(EstadisticasCitaModule);