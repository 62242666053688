import { BaseDto } from '@/shared/dtos/base-dto';
import { ApexCharsSeries } from './ApexCharsSeries';
import { DashNameDataDto } from './DashNameDataDto';
import { DashTipoDatoFecha } from './DashTipoDatoFecha';
import { DashTipoDatoPorcen } from './DashTipoDatoPorcen';

export class DashTotalTipoCitas extends BaseDto {
    public datos_agrupados_por_fecha!:DashTipoDatoFecha[];
    public datos_agrupados_por_porcentajes!:DashTipoDatoPorcen[];
    public citas_comparacion_meses!:ApexCharsSeries[];
    public sum_este_mes:DashNameDataDto= new DashNameDataDto();
    public sum_mes_pasado:DashNameDataDto= new DashNameDataDto();
 } 
