import { API } from '@/shared/api';
import { DashTotalTipoCitas } from '@/shared/dtos/DashboardsDtos/DashTotalTipoCitas';
import { ssmHttpService } from '@/shared/services/http-service';
import { store } from '@/store/store';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
    namespaced: true,
    name: 'DashTotalTipoCitasModule',
    store,
    dynamic: true
})
class DashTotalTipoCitasModule extends VuexModule {
    public DashTotalTipoCitas: DashTotalTipoCitas = new DashTotalTipoCitas();

    @Action({ commit: 'onGetDashTotalTipoCitas' })
    public async getDashTotalTipoCitases() {
        return await ssmHttpService.get(API.AgendaAgruCitas);
    }

    @Mutation
    public onGetDashTotalTipoCitas(res: DashTotalTipoCitas) {
        this.DashTotalTipoCitas = res;//new DashTotalTipoCitas(res);
    }

}
export default getModule(DashTotalTipoCitasModule);