




























































import { Component, Vue } from "vue-property-decorator";
import DashTotalTipoCitasModule from "@/store/modules/Dashboards/Empresarial/DashTotalTipoCitas-module";
import EstadisticasCitaModule from "@/store/modules/Dashboards/Empresarial/EstadisticasCita-module";
@Component({
  components: {
    ChartComparacionEntreMeses: () =>
      import("./Agenda/ChartComparacionEntreMeses.vue"),
    ChartEvolucionAgenda: () => import("./Agenda/ChartEvolucionAgenda.vue"),
    ChartTiposDeCita: () => import("./Agenda/ChartTiposDeCita.vue"),
    FichaPacienteEstadisticas: () =>
      import("./Agenda/FichaPacienteEstadisticas.vue"),
    TablaCitasPacientes: () => import("./Agenda/TablaCitasPacientes.vue")
  }
})
export default class DashAgenda extends Vue {
  public created() {
    DashTotalTipoCitasModule.getDashTotalTipoCitases();
    EstadisticasCitaModule.getEstadisticasCitaas();
  }
  public get paci_masCitasConf() {
    return EstadisticasCitaModule.EstadisticasCita
      .paciente_mas_citas_confirmadas;
  }
  public get paci_menosCitasConf() {
    return EstadisticasCitaModule.EstadisticasCita
      .paciente_menos_citas_confirmadas;
  }
  public get paci_masCitasCance() {
    return EstadisticasCitaModule.EstadisticasCita
      .paciente_mas_citas_canceladas;
  }
  public get paci_menosCitasCance() {
    return EstadisticasCitaModule.EstadisticasCita
      .paciente_menos_citas_canceladas;
  }
}
